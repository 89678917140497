import React from 'react';

import { Headline, Text, InfoPageContainer } from '../components';

import { privacyContent } from '../data';

const PrivacyPolicy = () => (
  <InfoPageContainer>
    <Headline>Privacy Policy</Headline>
    {privacyContent.map((paragraph, index) => (
      <Text key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ))}
  </InfoPageContainer>
);

export default PrivacyPolicy;
